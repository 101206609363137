import clsx from 'clsx';
import { FC } from 'react';

import { Text, TextAsParagraph, TextAsSpan } from '../Text';
import styles from './BodyText.module.scss';

type TagProps = TextAsSpan | TextAsParagraph;

export type BodyTextProps = TagProps & {
  variant?:
    | 'body-text-1'
    | 'body-text-2'
    | 'body-text-regular'
    | 'body-text-subheading';
};

export const BodyText: FC<BodyTextProps> = ({
  tag = 'p',
  dataTest = 'body-text',
  color = 'text--black',
  variant = 'body-text-1',

  fontWeight,
  className,
  ...props
}) => (
  <Text
    {...props}
    tag={tag}
    color={color}
    fontWeight={fontWeight}
    dataTest={dataTest}
    className={clsx(styles[variant], className)}
  />
);
