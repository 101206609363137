import {
  CountryType,
  SupportedLanguage,
  SupportedLocaleTag,
} from '@checkout-ui/shared-domain-entities';

import type {
  CountryLanguageLocale,
  CountrySupportedLanguageForUI,
} from '../types';

//TODO Should we use the same CountryLanguageLocale type as the default?
export const HPP_STRIPE_COUNTRY_LANGUAGE_LOCALE: CountryLanguageLocale = {
  [CountryType.AT]: {
    [SupportedLanguage.German]: SupportedLocaleTag.AustrianGerman,
    [SupportedLanguage.English]: SupportedLocaleTag.AmericanEnglish,
    [SupportedLanguage.Dutch]: SupportedLocaleTag.Dutch,
    [SupportedLanguage.French]: SupportedLocaleTag.French,
    [SupportedLanguage.Spanish]: SupportedLocaleTag.Spanish,
    [SupportedLanguage.Finnish]: SupportedLocaleTag.Finnish,
  },
  [CountryType.CH]: {
    [SupportedLanguage.German]: SupportedLocaleTag.SwissGerman,
    [SupportedLanguage.French]: SupportedLocaleTag.SwissFrench,
    [SupportedLanguage.Italian]: SupportedLocaleTag.SwissItalian,
    [SupportedLanguage.English]: SupportedLocaleTag.AmericanEnglish,
  },
  [CountryType.DE]: {
    [SupportedLanguage.German]: SupportedLocaleTag.German,
    [SupportedLanguage.English]: SupportedLocaleTag.AmericanEnglish,
    [SupportedLanguage.Dutch]: SupportedLocaleTag.Dutch,
    [SupportedLanguage.French]: SupportedLocaleTag.French,
    [SupportedLanguage.Spanish]: SupportedLocaleTag.Spanish,
    [SupportedLanguage.Finnish]: SupportedLocaleTag.Finnish,
  },
  [CountryType.FI]: {
    [SupportedLanguage.Finnish]: SupportedLocaleTag.Finnish,
    [SupportedLanguage.English]: SupportedLocaleTag.AmericanEnglish,
    [SupportedLanguage.German]: SupportedLocaleTag.German,
    [SupportedLanguage.Dutch]: SupportedLocaleTag.Dutch,
    [SupportedLanguage.French]: SupportedLocaleTag.French,
    [SupportedLanguage.Spanish]: SupportedLocaleTag.Spanish,
  },
  [CountryType.FR]: {
    [SupportedLanguage.French]: SupportedLocaleTag.French,
    [SupportedLanguage.English]: SupportedLocaleTag.AmericanEnglish,
    [SupportedLanguage.German]: SupportedLocaleTag.German,
    [SupportedLanguage.Dutch]: SupportedLocaleTag.Dutch,
    [SupportedLanguage.Spanish]: SupportedLocaleTag.Spanish,
    [SupportedLanguage.Finnish]: SupportedLocaleTag.Finnish,
  },
  [CountryType.GB]: {
    [SupportedLanguage.English]: SupportedLocaleTag.BritishEnglish,
  },
  [CountryType.NL]: {
    [SupportedLanguage.Dutch]: SupportedLocaleTag.Dutch,
    [SupportedLanguage.English]: SupportedLocaleTag.AmericanEnglish,
    [SupportedLanguage.German]: SupportedLocaleTag.German,
    [SupportedLanguage.French]: SupportedLocaleTag.French,
    [SupportedLanguage.Spanish]: SupportedLocaleTag.Spanish,
    [SupportedLanguage.Finnish]: SupportedLocaleTag.Finnish,
  },
  [CountryType.NO]: {
    [SupportedLanguage.Norwegian]: SupportedLocaleTag.Norwegian,
    [SupportedLanguage.NorwegianBokmal]: SupportedLocaleTag.Norwegian,
    [SupportedLanguage.English]: SupportedLocaleTag.AmericanEnglish,
  },
  [CountryType.SE]: {
    [SupportedLanguage.Swedish]: SupportedLocaleTag.Swedish,
    [SupportedLanguage.English]: SupportedLocaleTag.AmericanEnglish,
  },
  [CountryType.DK]: {
    [SupportedLanguage.Danish]: SupportedLocaleTag.Danish,
    [SupportedLanguage.English]: SupportedLocaleTag.AmericanEnglish,
  },
  [CountryType.ES]: {
    [SupportedLanguage.Spanish]: SupportedLocaleTag.Spanish,
    [SupportedLanguage.English]: SupportedLocaleTag.AmericanEnglish,
    [SupportedLanguage.German]: SupportedLocaleTag.German,
    [SupportedLanguage.Dutch]: SupportedLocaleTag.Dutch,
    [SupportedLanguage.French]: SupportedLocaleTag.French,
    [SupportedLanguage.Finnish]: SupportedLocaleTag.Finnish,
  },
  [CountryType.IT]: {
    [SupportedLanguage.Italian]: SupportedLocaleTag.Italian,
    [SupportedLanguage.English]: SupportedLocaleTag.AmericanEnglish,
    [SupportedLanguage.German]: SupportedLocaleTag.German,
    [SupportedLanguage.Dutch]: SupportedLocaleTag.Dutch,
    [SupportedLanguage.French]: SupportedLocaleTag.French,
    [SupportedLanguage.Spanish]: SupportedLocaleTag.Spanish,
    [SupportedLanguage.Finnish]: SupportedLocaleTag.Finnish,
  },
};

//TODO Should we use the same CountrySupportedLanguageForUI type as the default?
export const HPP_STRIPE_COUNTRY_SUPPORTED_LANGUAGES_FOR_UI: CountrySupportedLanguageForUI =
  {
    [CountryType.AT]: {
      options: [
        { value: SupportedLocaleTag.AustrianGerman, displayNameCode: 'de' },
        { value: SupportedLocaleTag.Dutch, displayNameCode: 'nl' },
        { value: SupportedLocaleTag.French, displayNameCode: 'fr' },
        { value: SupportedLocaleTag.Spanish, displayNameCode: 'es' },
        { value: SupportedLocaleTag.Finnish, displayNameCode: 'fi' },
        { value: SupportedLocaleTag.AmericanEnglish, displayNameCode: 'en' },
      ],
      defaultValue: SupportedLocaleTag.AustrianGerman,
    },
    [CountryType.CH]: {
      options: [
        { value: SupportedLocaleTag.SwissGerman, displayNameCode: 'de' },
        { value: SupportedLocaleTag.SwissFrench, displayNameCode: 'fr' },
        { value: SupportedLocaleTag.SwissItalian, displayNameCode: 'it' },
        { value: SupportedLocaleTag.AmericanEnglish, displayNameCode: 'en' },
      ],
      defaultValue: SupportedLocaleTag.SwissGerman,
    },
    [CountryType.DE]: {
      options: [
        { value: SupportedLocaleTag.German, displayNameCode: 'de' },
        { value: SupportedLocaleTag.Dutch, displayNameCode: 'nl' },
        { value: SupportedLocaleTag.French, displayNameCode: 'fr' },
        { value: SupportedLocaleTag.Spanish, displayNameCode: 'es' },
        { value: SupportedLocaleTag.Finnish, displayNameCode: 'fi' },
        { value: SupportedLocaleTag.AmericanEnglish, displayNameCode: 'en' },
      ],
      defaultValue: SupportedLocaleTag.German,
    },
    [CountryType.FI]: {
      options: [
        { value: SupportedLocaleTag.Finnish, displayNameCode: 'fi' },
        { value: SupportedLocaleTag.German, displayNameCode: 'de' },
        { value: SupportedLocaleTag.Dutch, displayNameCode: 'nl' },
        { value: SupportedLocaleTag.French, displayNameCode: 'fr' },
        { value: SupportedLocaleTag.Spanish, displayNameCode: 'es' },
        { value: SupportedLocaleTag.AmericanEnglish, displayNameCode: 'en' },
      ],
      defaultValue: SupportedLocaleTag.Finnish,
    },
    [CountryType.FR]: {
      options: [
        { value: SupportedLocaleTag.French, displayNameCode: 'fr' },
        { value: SupportedLocaleTag.German, displayNameCode: 'de' },
        { value: SupportedLocaleTag.Dutch, displayNameCode: 'nl' },
        { value: SupportedLocaleTag.Spanish, displayNameCode: 'es' },
        { value: SupportedLocaleTag.Finnish, displayNameCode: 'fi' },
        { value: SupportedLocaleTag.AmericanEnglish, displayNameCode: 'en' },
      ],
      defaultValue: SupportedLocaleTag.French,
    },
    [CountryType.GB]: {
      options: [
        { value: SupportedLocaleTag.BritishEnglish, displayNameCode: 'en' },
      ],
      defaultValue: SupportedLocaleTag.BritishEnglish,
    },
    [CountryType.NL]: {
      options: [
        { value: SupportedLocaleTag.Dutch, displayNameCode: 'nl' },
        { value: SupportedLocaleTag.German, displayNameCode: 'de' },
        { value: SupportedLocaleTag.French, displayNameCode: 'fr' },
        { value: SupportedLocaleTag.Spanish, displayNameCode: 'es' },
        { value: SupportedLocaleTag.Finnish, displayNameCode: 'fi' },
        { value: SupportedLocaleTag.AmericanEnglish, displayNameCode: 'en' },
      ],
      defaultValue: SupportedLocaleTag.Dutch,
    },
    [CountryType.NO]: {
      options: [
        { value: SupportedLocaleTag.Norwegian, displayNameCode: 'nb' },
        { value: SupportedLocaleTag.AmericanEnglish, displayNameCode: 'en' },
      ],
      defaultValue: SupportedLocaleTag.Norwegian,
    },
    [CountryType.SE]: {
      options: [
        { value: SupportedLocaleTag.Swedish, displayNameCode: 'sv' },
        { value: SupportedLocaleTag.AmericanEnglish, displayNameCode: 'en' },
      ],
      defaultValue: SupportedLocaleTag.Swedish,
    },
    [CountryType.DK]: {
      options: [
        { value: SupportedLocaleTag.Danish, displayNameCode: 'da' },
        { value: SupportedLocaleTag.AmericanEnglish, displayNameCode: 'en' },
      ],
      defaultValue: SupportedLocaleTag.Danish,
    },
    [CountryType.ES]: {
      options: [
        { value: SupportedLocaleTag.Spanish, displayNameCode: 'es' },
        { value: SupportedLocaleTag.German, displayNameCode: 'de' },
        { value: SupportedLocaleTag.Dutch, displayNameCode: 'nl' },
        { value: SupportedLocaleTag.French, displayNameCode: 'fr' },
        { value: SupportedLocaleTag.Finnish, displayNameCode: 'fi' },
        { value: SupportedLocaleTag.AmericanEnglish, displayNameCode: 'en' },
      ],
      defaultValue: SupportedLocaleTag.Spanish,
    },
    [CountryType.IT]: {
      options: [
        { value: SupportedLocaleTag.Italian, displayNameCode: 'it' },
        { value: SupportedLocaleTag.German, displayNameCode: 'de' },
        { value: SupportedLocaleTag.Dutch, displayNameCode: 'nl' },
        { value: SupportedLocaleTag.French, displayNameCode: 'fr' },
        { value: SupportedLocaleTag.Spanish, displayNameCode: 'es' },
        { value: SupportedLocaleTag.Finnish, displayNameCode: 'fi' },
        { value: SupportedLocaleTag.AmericanEnglish, displayNameCode: 'en' },
      ],
      defaultValue: SupportedLocaleTag.Italian,
    },
  };
