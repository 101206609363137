import { trackEvent } from '@checkout-ui/demo-shop/services';
import { InputLabel } from '@checkout-ui/design-system/InputLabel';
import { SelectInput } from '@checkout-ui/design-system/SelectInput';
import type { SelectInputOption } from '@checkout-ui/design-system/SelectInput/types';
import { useIntl } from '@checkout-ui/shared-context-locale';
import { useCallback, useMemo } from 'react';

import { useConfigurationPanel } from './context/ConfigurationPanelContext';
import styles from './CountrySelector.module.scss';

export const CountrySelector = () => {
  const { config, handleConfigChange } = useConfigurationPanel();
  const { formatMessage } = useIntl();

  const regionNames = useMemo(
    () => new Intl.DisplayNames([config.language], { type: 'region' }),
    [config.language]
  );

  const countryOptions: SelectInputOption<string>[] =
    config.available_countries.map((countryCode) => {
      const cc = countryCode.toUpperCase();

      return {
        value: cc,
        title: regionNames.of(cc) || cc,
      };
    });

  const onChange = useCallback(
    (value: string) => {
      handleConfigChange(value, 'country');
      trackEvent(`${value.toLowerCase()} country selected`);
    },
    [handleConfigChange]
  );

  return (
    <>
      <InputLabel htmlFor="country" className={styles['label']}>
        {formatMessage({ id: 'demo-shop.configuration.country' })}
      </InputLabel>
      <SelectInput
        placeholder={formatMessage({
          id: 'demo-shop.configuration.country-placeholder',
        })}
        options={countryOptions}
        value={config.country}
        onChange={onChange}
        testId="country"
        name="country"
        id="country"
      />
    </>
  );
};
