import clsx from 'clsx';
import { ButtonHTMLAttributes, FC, ReactNode } from 'react';

import { Spinner, SpinnerVariant } from '../../Spinner';
import styles from './ButtonBase.module.scss';

export interface ButtonBaseProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  isLoading?: boolean;
  children: ReactNode;
  dataTest?: string;
  spinnerVariant?: keyof typeof SpinnerVariant;
  tabIndex?: number;
}

export const ButtonBase: FC<ButtonBaseProps> = ({
  children,
  isLoading,
  disabled,
  className,
  spinnerVariant = 'primary',
  onKeyDown,
  tabIndex,
  dataTest = 'btn-base',

  ...otherProps
}) => {
  const computedTabIndex =
    // -1 = Not focusable, 0 = Focusable in tab order
    tabIndex !== undefined ? tabIndex : disabled ? -1 : 0;

  return (
    <button
      {...otherProps}
      className={clsx(styles.btn, className)}
      data-test={dataTest}
      aria-disabled={disabled}
      aria-busy={isLoading}
      disabled={disabled}
      tabIndex={computedTabIndex}
    >
      {isLoading ? <Spinner variant={spinnerVariant} /> : children}
    </button>
  );
};
