import { trackEvent } from '@checkout-ui/demo-shop/services';
import { InputLabel } from '@checkout-ui/design-system/InputLabel';
import { SelectInput } from '@checkout-ui/design-system/SelectInput';
import { SelectInputOption } from '@checkout-ui/design-system/SelectInput/types';
import {
  DEFAULT_COUNTRY_SUPPORTED_LANGUAGES_FOR_UI,
  getLocalLanguageName,
  useIntl,
} from '@checkout-ui/shared-context-locale';
import { SupportedLocaleTag } from '@checkout-ui/shared-domain-entities';
import { useCallback, useEffect, useMemo } from 'react';

import { useConfigurationPanel } from './context/ConfigurationPanelContext';
import styles from './CountrySelector.module.scss';

export const LanguageSelector = () => {
  const { config, handleConfigChange } = useConfigurationPanel();
  const { formatMessage } = useIntl();

  const countryConfig =
    DEFAULT_COUNTRY_SUPPORTED_LANGUAGES_FOR_UI[config.country];
  const languages: SelectInputOption<SupportedLocaleTag>[] =
    countryConfig.options.map((language) => ({
      value: language.value,
      title: getLocalLanguageName(language.displayNameCode),
    }));

  const defaultLanguageOption = useMemo(() => {
    return languages.find(
      (option) => option.value === countryConfig.defaultValue
    )!;
  }, [countryConfig.defaultValue, languages]);

  const countryDerivedSelectedLanguage = useMemo(() => {
    const languageOption = languages.find(
      (lang) => lang.value === config.language
    );
    return languageOption ? languageOption.value : defaultLanguageOption.value;
  }, [languages, config.language, defaultLanguageOption]);

  useEffect(() => {
    if (countryDerivedSelectedLanguage !== config.language) {
      handleConfigChange(countryDerivedSelectedLanguage, 'language');
    }
  }, [handleConfigChange, countryDerivedSelectedLanguage, config.language]);

  const onChange = useCallback(
    (value: SupportedLocaleTag) => {
      handleConfigChange(value, 'language');
      trackEvent(`${value.toLowerCase()} language selected`);
    },
    [handleConfigChange]
  );

  return (
    <>
      <InputLabel htmlFor="language" className={styles['label']}>
        {formatMessage({ id: 'demo-shop.configuration.language' })}
      </InputLabel>
      <SelectInput<SupportedLocaleTag>
        placeholder={formatMessage({
          id: 'demo-shop.configuration.language-placeholder',
        })}
        options={languages}
        value={countryDerivedSelectedLanguage}
        onChange={onChange}
        testId="language"
        name="language"
        id="language"
      />
    </>
  );
};
