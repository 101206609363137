import clsx from 'clsx';
import { forwardRef } from 'react';

import styles from './InputLabel.module.scss';

export interface InputLabelProps extends React.HTMLProps<HTMLLabelElement> {
  children: React.ReactNode;
  htmlFor: string;
  disabled?: boolean;
  className?: string;
  dataTest?: string;
}

export const InputLabel = forwardRef<HTMLLabelElement, InputLabelProps>(
  (
    {
      children,
      htmlFor,
      disabled,
      className,
      dataTest = `${htmlFor}-label`,

      ...otherProps
    },
    ref
  ) => {
    return (
      <label
        {...otherProps}
        ref={ref}
        data-test={dataTest}
        htmlFor={htmlFor}
        className={clsx(
          styles['input-label'],
          {
            [styles['disabled']]: disabled,
          },
          className
        )}
      >
        {children}
      </label>
    );
  }
);

InputLabel.displayName = 'InputLabel';

export default InputLabel;
