import { SelectInputOption, SelectInputValue } from './types';

export const getSelectInputOptionStringValue = <V extends SelectInputValue>(
  option: SelectInputOption<V> | undefined
): string => {
  if (!option) {
    return '';
  }

  return option?.title || String(option.value);
};

export const getSelectInputValuePreview = <V extends SelectInputValue>(
  valueOption: SelectInputOption<V> | undefined,
  selectedOption: SelectInputOption<V> | undefined,
  defaultValueOption: SelectInputOption<V> | undefined,
  placeholder: string | undefined
): string => {
  const topPriorityOptionPreview = getSelectInputOptionStringValue(
    valueOption ?? selectedOption ?? defaultValueOption
  );

  if (!topPriorityOptionPreview) {
    return placeholder || ' ';
  }

  return topPriorityOptionPreview;
};
