import clsx from 'clsx';
import { lazy, Suspense } from 'react';

import styles from './Logo.module.scss';

export enum LogoSize {
  xs, // 30px x 10px
  sm, // 47px x 16px
  md, // 59px x 20px
}
export interface LogoProps {
  size?: keyof typeof LogoSize;
}

const LogoSvg = lazy(() =>
  import('@checkout-ui/lazy-svgs/logo.svg')
    .then((svg) => {
      return { default: svg.ReactComponent };
    })
    .catch((error) => {
      console.error('Error loading logo icon', error);
      return { default: () => null };
    })
);

const Logo = ({ size = 'md' }: LogoProps) => {
  return (
    <span className={clsx(styles[size], styles['container'])}>
      <Suspense>
        <LogoSvg data-test="logo-icon" className={styles[size]} />
      </Suspense>
    </span>
  );
};

export default Logo;
