import clsx from 'clsx';
import React from 'react';

import { ButtonBase, ButtonBaseProps } from '../ButtonBase';
import styles from './SandboxPrimaryButton.module.scss';

export interface SandboxPrimaryButtonProps extends ButtonBaseProps {
  size?: 'medium' | 'large';
}

export const SandboxPrimaryButton: React.FC<SandboxPrimaryButtonProps> = ({
  dataTest = 'btn-external',
  size = 'large',
  ...props
}) => {
  return (
    <ButtonBase
      {...props}
      dataTest={dataTest}
      className={clsx(styles['btn--external'], styles[size])}
    />
  );
};
