import { Spinner as SpinnerIcon } from '@checkout-ui/internal-svgs/Spinner';
import clsx from 'clsx';

import { Icon, IconSize } from '../Icon';
import styles from './Spinner.module.scss';

export enum SpinnerVariant {
  primary = 'primary',
  secondary = 'secondary',
  neutral = 'grey-400',
}

export type SpinnerProps = {
  variant?: keyof typeof SpinnerVariant;
  size?: keyof typeof IconSize;
};

export const Spinner = ({ variant = 'primary', size = 'md' }: SpinnerProps) => (
  <Icon
    icon={SpinnerIcon}
    className={clsx(styles.spinner, {
      [styles[`spinner--${variant}`]]: variant,
    })}
    size={size}
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  />
);

Spinner.displayName = 'Spinner';
