import * as Sentry from '@sentry/browser';

import { createConfigParser } from './createConfigParser';
import { createFallbackConfig } from './createFallbackConfig';
import {
  ConfigurationLoader,
  ConfigurationLoaderEvent,
  ConfigurationLoaderEventHandler,
  ConfigurationLoaderOptions,
  ConfigurationParserDefinition,
  ConfigurationStatus,
  InferConfiguration,
} from './types';

const statusTriggersEvent: Partial<
  Record<ConfigurationStatus, ConfigurationLoaderEvent[]>
> = {
  [ConfigurationStatus.Loading]: [ConfigurationLoaderEvent.Loading],
  [ConfigurationStatus.Ready]: [
    ConfigurationLoaderEvent.Ready,
    ConfigurationLoaderEvent.Finished,
  ],
  [ConfigurationStatus.Error]: [
    ConfigurationLoaderEvent.Error,
    ConfigurationLoaderEvent.Finished,
  ],
};

const getHandlersForStatus = <D extends ConfigurationParserDefinition>(
  handlersConfiguration: Record<
    ConfigurationLoaderEvent,
    Set<ConfigurationLoaderEventHandler<D>>
  >,
  status: ConfigurationStatus
): ConfigurationLoaderEventHandler<D>[] => {
  const currentStatusTriggeredEvents = statusTriggersEvent[status] || [];

  return currentStatusTriggeredEvents.reduce<
    ConfigurationLoaderEventHandler<D>[]
  >((memo, triggerEvent) => {
    return memo.concat(Array.from(handlersConfiguration[triggerEvent]));
  }, []);
};

export const createConfigurationLoader = <
  D extends ConfigurationParserDefinition
>(
  definition: D,
  options: ConfigurationLoaderOptions
): ConfigurationLoader<D> => {
  let src = options.src;
  let status: ConfigurationStatus = ConfigurationStatus.Initial;
  const fallbackConfig = createFallbackConfig(definition);
  const parseConfig = createConfigParser(definition);
  let config: InferConfiguration<D> = fallbackConfig;
  let error: null | Error = null;

  const handlers: Record<
    ConfigurationLoaderEvent,
    Set<ConfigurationLoaderEventHandler<D>>
  > = {
    [ConfigurationLoaderEvent.Loading]: new Set(),
    [ConfigurationLoaderEvent.Ready]: new Set(),
    [ConfigurationLoaderEvent.Error]: new Set(),
    [ConfigurationLoaderEvent.Finished]: new Set(),
  };

  const setStatus = async (nextStatus: ConfigurationStatus) => {
    status = nextStatus;

    const scheduledHandlers = getHandlersForStatus(handlers, nextStatus);

    // the handlers are invoked in the sequence they were added
    await scheduledHandlers.reduce(
      (memo, handler) =>
        memo.then(async () => {
          try {
            await handler({
              status,
              src,
              config,
              error,
            });
          } catch (err) {
            console.error(err);
          }
        }),
      Promise.resolve()
    );
  };

  const on = (
    event: ConfigurationLoaderEvent,
    handler: ConfigurationLoaderEventHandler<D>
  ): boolean => {
    if (handlers[event].has(handler)) {
      return false;
    }

    handlers[event].add(handler);

    const currentStatusTriggeredEvents = statusTriggersEvent[status] || [];

    // lifecycle events always trigger the handler, no matter if the handler
    // was added before or after the event has taken place
    if (currentStatusTriggeredEvents.includes(event)) {
      try {
        handler({
          status,
          src,
          config,
          error,
        });
      } catch (err) {
        console.error(err);
      }
    }

    return true;
  };

  const off = (
    event: ConfigurationLoaderEvent,
    handler: ConfigurationLoaderEventHandler<D>
  ): boolean => {
    handlers[event].delete(handler);

    return true;
  };

  const updateOptions = (update: Partial<ConfigurationLoaderOptions>) => {
    src = update.src || src;
  };

  return {
    get src() {
      return src;
    },
    get status() {
      return status;
    },
    get config() {
      return config;
    },
    get error() {
      return error;
    },
    get fallbackConfig() {
      return fallbackConfig;
    },
    on,
    off,
    fetchConfig: async () => {
      if (status !== ConfigurationStatus.Initial) {
        return;
      }

      await setStatus(ConfigurationStatus.Loading);
      Sentry.addBreadcrumb({
        category: 'Configuration',
        level: 'debug',
        message: 'Loading started',
        data: {
          src,
        },
      });

      try {
        const data = await fetch(src).then((rs) => {
          if (rs.status >= 400) {
            throw new Error(
              `could not get configuration file at ${src} (http error ${rs.status})`
            );
          }

          return rs.json();
        });

        Sentry.addBreadcrumb({
          category: 'Configuration',
          level: 'debug',
          message: 'Response received',
          data,
        });
        config = parseConfig(data);
        Sentry.addBreadcrumb({
          category: 'Configuration',
          level: 'debug',
          message: 'Ready',
          data: config,
        });
        await setStatus(ConfigurationStatus.Ready);
      } catch (err) {
        config = fallbackConfig;
        const safeErr =
          err instanceof Error
            ? // FIXME: remove this after upgrading typescript, see: https://ozean12.atlassian.net/browse/PURCHASE-1378
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              new Error('configuration loading failed', { cause: err })
            : new Error(
                'configuration loading failed, received unknown object instead of error'
              );
        error = safeErr;
        await setStatus(ConfigurationStatus.Error);
        Sentry.captureException(safeErr, { level: 'fatal' });
      }
    },
    updateOptions,
    events: ConfigurationLoaderEvent,
  };
};
