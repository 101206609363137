import clsx from 'clsx';
import { FC, SVGProps, useEffect, useState } from 'react';

import styles from './SvgIcon.module.scss';

export enum IconSize {
  xs,
  sm,
  md,
  lg,
}

export type IconType = FC<SVGProps<SVGSVGElement>>;

export interface IconProps extends SVGProps<SVGSVGElement> {
  name: string;
  size?: keyof typeof IconSize;
}
const icons = {
  check: async () => await import(`@checkout-ui/internal-svgs/check.svg`),
  'check-circle': async () =>
    await import(`@checkout-ui/internal-svgs/check-circle.svg`),
  'check-circle-green': async () =>
    await import(`@checkout-ui/internal-svgs/check-circle-green.svg`),
  'check-locale': async () =>
    await import(`@checkout-ui/internal-svgs/check-locale.svg`),
  chevron: async () => await import(`@checkout-ui/internal-svgs/chevron.svg`),
  close: async () => await import(`@checkout-ui/internal-svgs/close.svg`),
  'cloud-lock': async () =>
    await import(`@checkout-ui/internal-svgs/cloud-lock.svg`),
  desktop: async () => await import(`@checkout-ui/internal-svgs/desktop.svg`),
  error: async () => await import(`@checkout-ui/internal-svgs/error.svg`),
  info: async () => await import(`@checkout-ui/internal-svgs/info.svg`),
  'language-bubble': async () =>
    await import(`@checkout-ui/internal-svgs/language-bubble.svg`),
  menu: async () => await import(`@checkout-ui/internal-svgs/menu.svg`),
  mobile: async () => await import(`@checkout-ui/internal-svgs/mobile.svg`),
  'previous-arrow': async () =>
    await import(`@checkout-ui/internal-svgs/previous-arrow.svg`),
  'question-mark': async () =>
    await import(`@checkout-ui/internal-svgs/question-mark.svg`),
  send: async () => await import(`@checkout-ui/internal-svgs/send.svg`),
  'shopping-cart': async () =>
    await import(`@checkout-ui/internal-svgs/shopping-cart.svg`),
  spinner: async () => await import(`@checkout-ui/internal-svgs/spinner.svg`),
  tablet: async () => await import(`@checkout-ui/internal-svgs/tablet.svg`),
};

export type Icons = keyof typeof icons;

export function SvgIcon({ name, size = 'sm', ...svgProps }: IconProps) {
  const [Icon, setIcon] = useState<IconType | null>(null);

  const testId = name ? `${name}-icon` : 'icon';
  const getIcon = icons[name as Icons];

  useEffect(() => {
    //flag prevents state updates on unmounted components
    let isMounted = true;

    const importIcon = async () => {
      try {
        const imported = (await getIcon()).ReactComponent;

        if (isMounted) {
          setIcon(() => imported); // Ensure `setIcon` gets a valid component function
        }
      } catch (err) {
        console.error(`Error loading icon "${name}":`, err);
      }
    };

    importIcon();

    return () => {
      isMounted = false;
    };
  }, [name, getIcon]);

  return (
    <span
      className={
        Icon ? styles['container'] : clsx(styles[size], svgProps.className)
      }
    >
      {Icon && (
        <Icon
          {...svgProps}
          data-test={testId}
          className={clsx(styles['icon'], styles[size], svgProps.className)}
        />
      )}
    </span>
  );
}

export { icons as iconsList };
