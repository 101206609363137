import clsx from 'clsx';
import { FC } from 'react';

import Text, { TextAsHeading } from '../Text/Text';
import styles from './Heading.module.scss';

export type HeadingProps = Omit<TextAsHeading, 'fontWeight'>;

export const Heading: FC<HeadingProps> = ({
  tag = 'h2',
  dataTest = 'heading',
  color = 'text--black',
  className,

  ...props
}) => {
  const classForTag = `heading-${tag.split('h')[1]}`;

  return (
    <Text
      {...props}
      tag={tag}
      color={color}
      dataTest={dataTest}
      className={clsx(styles.heading, styles[classForTag], className)}
    />
  );
};

export default Heading;
