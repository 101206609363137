import clsx from 'clsx';
import { ChangeEventHandler, ReactElement, useMemo, useState } from 'react';

import { SvgIcon } from '../LazyIcon';
import {
  getSelectInputOptionStringValue,
  getSelectInputValuePreview,
} from './getSelectInputOptionStringValue';
import styles from './SelectInput.module.scss';
import { SelectInputOption, SelectInputProps, SelectInputValue } from './types';

const UNSET_OPTION_VALUE = 'UNSET_OPTION_VALUE';

export const SelectInput = <V extends SelectInputValue = string>({
  options,
  defaultValue,
  value,
  onChange,
  hasError = false,
  placeholder,
  testId = 'select-input',
  className,
  ...props
}: SelectInputProps<V>): ReactElement | null => {
  const [selectedOptionIndex, setSelectedOptionIndex] = useState<number>(() => {
    const presetValue = value ?? defaultValue;

    return options.findIndex((option) => option.value === presetValue);
  });

  const selectedOption = useMemo<SelectInputOption<V> | undefined>(() => {
    // if the input is controlled, we rely on external properties
    if (value !== undefined) {
      return undefined;
    }

    return options[selectedOptionIndex];
  }, [value, options, selectedOptionIndex]);

  const onSelectChange = useMemo<ChangeEventHandler<HTMLSelectElement>>(
    () => (event) => {
      event.preventDefault();
      const eventValueIndex = options.findIndex(
        (option) => option.value === event.target.value
      );
      setSelectedOptionIndex(eventValueIndex);
      if (onChange && eventValueIndex >= 0) {
        onChange(options[eventValueIndex].value);
      }
    },
    [onChange, options]
  );

  const { valueOption, defaultValueOption } = useMemo(
    () =>
      options.reduce<{
        valueOption?: SelectInputOption<V>;
        defaultValueOption?: SelectInputOption<V>;
      }>((memo, option) => {
        if (option.value === value) {
          memo.valueOption = option;
        }

        if (option.value === defaultValue) {
          memo.defaultValueOption = option;
        }

        return memo;
      }, {}),
    [options, value, defaultValue]
  );

  const normalizedDefaultValue = useMemo<
    V | typeof UNSET_OPTION_VALUE | undefined
  >(() => {
    if (value) {
      return undefined;
    }

    return defaultValue ?? UNSET_OPTION_VALUE;
  }, [value, defaultValue]);

  return (
    <div
      className={clsx(styles.wrapper, hasError && styles.error, className)}
      data-test={testId}
    >
      <select
        className={styles.select}
        onChange={onSelectChange}
        defaultValue={normalizedDefaultValue}
        value={value}
        data-test={`${testId}__select`}
        {...props}
      >
        <option
          className={styles.disabledOption}
          disabled
          hidden
          value={UNSET_OPTION_VALUE}
          data-test={`${testId}__unset-option`}
        >
          {/* this option is required, so the select would work properly without default option */}
          {/* the text is displayed in safari and on mobile, so dashes are chosen
           as a string that does not require translation and still can be recognized by the user */}
          ------
        </option>
        {options.map((option) => (
          <option
            key={String(option.value)}
            value={option.value}
            data-test={`${testId}__option--${option.value.toLowerCase()}`}
          >
            {getSelectInputOptionStringValue(option)}
          </option>
        ))}
      </select>
      <div
        className={clsx(
          styles.preview,
          !selectedOption &&
            !valueOption &&
            !defaultValueOption &&
            styles.isPlaceholder
        )}
        data-test={`${testId}__preview`}
      >
        {getSelectInputValuePreview(
          valueOption,
          selectedOption,
          defaultValueOption,
          placeholder
        )}
      </div>
      <div className={styles.pointer} data-test={`${testId}__pointer`}>
        <SvgIcon className={styles.icon} name="chevron" />
      </div>
    </div>
  );
};
