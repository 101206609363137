import clsx from 'clsx';
import { FC } from 'react';

import Text, { TextAsParagraph, TextAsSpan } from '../Text/Text';
import styles from './Caption.module.scss';

export type CaptionProps = TextAsSpan | TextAsParagraph;

export const Caption: FC<CaptionProps> = ({
  tag = 'span' as CaptionProps['tag'],
  dataTest = 'text-caption',
  color = 'text--black',
  fontWeight,
  className,
  ...props
}) => (
  <Text
    {...props}
    tag={tag}
    color={color}
    fontWeight={fontWeight}
    dataTest={dataTest}
    className={clsx(styles.caption, className)}
  />
);
